/**
 * =========================================================
 * Material Kit 2 React - v2.0.0
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by www.creative-tim.com
 *
 *  =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

// Material Kit 2 React Base Styles
import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';

// Material Kit 2 React Helper Functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { fontWeightBold, size } = typography;
const { borderRadius } = borders;

export default {
	display: 'inline-flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: size.xs,
	fontWeight: fontWeightBold,
	borderRadius: borderRadius.lg,
	padding: `${pxToRem(6.302)} ${pxToRem(16.604)}`,
	lineHeight: 1.4,
	textAlign: 'center',
	textTransform: 'uppercase',
	userSelect: 'none',
	backgroundSize: '150% !important',
	backgroundPositionX: '25% !important',
	transition: 'all 150ms ease-in',

	'&:disabled': {
		pointerEvent: 'none',
		opacity: 0.65,
	},

	'& .material-icons': {
		fontSize: pxToRem(15),
		marginTop: pxToRem(-2),
	},
};
