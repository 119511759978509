/**
 * /*
 * =========================================================
 * Material Kit 2 React - v2.0.0
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by www.creative-tim.com
 *
 *  =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

// eslint-disable-next-line import/no-cycle
import { PageLayout, ProfileCard } from 'components';

export const Me = () => (
	<PageLayout>
		<ProfileCard />
	</PageLayout>
);
