/**
 * /* eslint-disable import/no-named-export
 *
 * @format
 */

import Avatar from './MKAvatar';
import Box from './MKBox';
import Button from './MKButton';
import Input from './MKInput';
import SocialButton from './MKSocialButton';
import Typography from './MKTypography';

export default { Avatar, Box, Button, Input, SocialButton, Typography };
