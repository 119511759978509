/**
 * =========================================================
 * Material Kit 2 React - v2.0.0
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by www.creative-tim.com
 *
 *  =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

// Material Kit 2 React base styles
import borders from 'assets/theme/base/borders';

// Material Kit 2 React helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { borderRadius } = borders;

export default {
	styleOverrides: {
		root: {
			display: 'block',
			padding: `${pxToRem(16)} ${pxToRem(16)} 0  ${pxToRem(16)}`,
			borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
		},
	},
};
