/** @format */

// eslint-disable-next-line import/no-cycle
import { LoadingButton, PageLayout } from 'components';

export const Components = () => (
	<PageLayout>
		<LoadingButton>Loading Button</LoadingButton>
	</PageLayout>
);
