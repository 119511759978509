/**
 * =========================================================
 * Material Kit 2 React - v2.0.0
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by www.creative-tim.com
 *
 *  =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

// Material Kit 2 React base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

// Material Kit 2 React helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { dark } = colors;
const { size, fontWeightBold } = typography;

export default {
	styleOverrides: {
		root: {
			display: 'block',
			minHeight: pxToRem(24),
			marginBottom: pxToRem(2),
		},

		label: {
			display: 'inline-block',
			fontSize: size.sm,
			fontWeight: fontWeightBold,
			color: dark.main,
			lineHeight: 1,
			transform: `translateY(${pxToRem(1)})`,
			marginLeft: pxToRem(4),

			'&.Mui-disabled': {
				color: dark.main,
			},
		},
	},
};
