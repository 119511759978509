/**
 *
 * @format
 */

/**
 *
 * @format
 */

/**
 * =========================================================
 * Material Kit 2 React - v2.0.0
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by www.creative-tim.com
 *
 *  =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

/**
  The boxShadow() function helps you to create a box shadow for an element
 */

// Material Kit 2 React helper functions
import rgba from 'assets/theme/functions/rgba';
import pxToRem from 'assets/theme/functions/pxToRem';

// eslint-disable-next-line default-param-last
const boxShadow = (offset = [], radius = [], color, opacity, inset = '') => {
	const [x, y] = offset;
	const [blur, spread] = radius;

	return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(
		color,
		opacity
	)}`;
};

export default boxShadow;
