/**
 * =========================================================
 * Material Kit 2 React - v2.0.0
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by www.creative-tim.com
 *
 *  =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for MKAvatar
import MKAvatarRoot from 'components/material-ui-kit/MKAvatar/MKAvatarRoot';

const MKAvatar = forwardRef(({ bgColor, size, shadow, ...rest }, ref) => (
	<MKAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
));

// Setting default values for the props of MKAvatar
MKAvatar.defaultProps = {
	bgColor: 'transparent',
	size: 'md',
	shadow: 'none',
};

// Typechecking props for the MKAvatar
MKAvatar.propTypes = {
	bgColor: PropTypes.oneOf([
		'transparent',
		'primary',
		'secondary',
		'info',
		'success',
		'warning',
		'error',
		'light',
		'dark',
	]),
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
	shadow: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'inset']),
};

export default MKAvatar;
